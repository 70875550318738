import React, {useState} from 'react'
// import useInView from "react-cool-inview";
import Img from 'gatsby-image';
import parse from 'html-react-parser'
import VisibilitySensor from 'react-visibility-sensor';

export default function GoogleTimelineYear({year}) {

  const [isInview, setIsInview] = useState(false);
      
  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }

  return (
  <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <label className={isInview ? 'is_inview year' : 'year'}>{year}</label>
      }
    </VisibilitySensor>
      
    
  )
}

import React, { Component } from 'react';
import GoogleIntro from '../../components/googlePage/GoogleIntro';
import GoogleMiddle from '../../components/googlePage/GoogleMiddle';
import GoogleTimeline from '../../components/googlePage/GoogleTimeline';
import GoogleTimelineYear from '../../components/googlePage/GoogleTimelineYear';
import "../../sass/pages/_google_timeline.scss";
import { graphql } from "gatsby"
import Seo from '../../components/seo'
import {SchemaLinksToAbsolute} from '../../helpers/SchemaLinksToAbsolute'

class GoogleAdsVisualHistory extends Component {
  componentDidMount(){
    if(typeof document !== 'undefined'){
      document.querySelector('body').setAttribute('data-bg-color', 'bg-white')
      document.querySelector('body').setAttribute('data-class', '')
    }
  }
  render() {

    const data = this.props.data.wpPage.acf_googleAdsTimeline;
    const yearRepeater = data.yearRepeater;
    let countYears = 1;
  
 

    return (
      <>
        <Seo 
        title={this.props.data.wpPage.seo.title} 
        description={this.props.data.wpPage.seo.metaDesc} 
        date={this.props.data.wpPage.date}
        slug={'/insights/'+this.props.data.wpPage.slug+'/'}
        robotsFollow={this.props.data.wpPage.seo.metaRobotsNofollow}
        robotsIndex={this.props.data.wpPage.seo.metaRobotsNoindex} 
        canonicalUrl={'/insights'+this.props.data.wpPage.seo.canonical}
        />
        {this.props.data.wpPage.seo.schema.raw !== undefined ? 
            <SchemaLinksToAbsolute schemaRaw={this.props.data.wpPage.seo.schema.raw} /> 
        : "" }
        <div id="body"  className='google_timeline scrolling_image'  data-bg-color="bg-white">	
            
            <GoogleIntro data={data} />
            <GoogleMiddle data={data} />

            <section className="timeline-wrap">
                
              {
                yearRepeater.map((year) => {
                  return(
                    <div className="year">
                    <GoogleTimelineYear year={year.year} />
                    {
                      year.event.map((event) => {
                        return(
                          <GoogleTimeline event={event} eventIndex={countYears++} />  
                        )
                      })
                    }
                  </div>
                  )
                })
              }
            </section>
          </div>
        
      </>
    );
  }
}



export const query = graphql`
  { 
    wpPage(id: {eq: "cG9zdDo3ODM="}) {
      seo {
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        canonical
        schema{
          raw
        }
      }
      date
      slug
      title
      acf_googleAdsTimeline {
        heroImage1Google {
          altText
          localFile {
            publicURL
            extension
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        heroImage2Google {
          altText
          localFile {
            publicURL
            extension
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        heroImage3Google {
          altText
          localFile {
            publicURL
            extension
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        heroImage4Google {
          altText
          localFile {
            publicURL
            extension
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        introImage1Google {
          altText
          localFile {
            publicURL
            extension
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        introImage2Google {
          altText
          localFile {
            publicURL
            extension
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        introImage3Google {
          altText
          localFile {
            publicURL
            extension
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        introText
        yearRepeater {
          year
          event {
            text
            title
            date(formatString: "")
            imageGatsbyGoogle{
              altText
              localFile {
                publicURL
                extension
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default GoogleAdsVisualHistory


import React, {useState} from 'react'
// import useInView from "react-cool-inview";
import Img from 'gatsby-image';
import parse from 'html-react-parser'
import VisibilitySensor from 'react-visibility-sensor';

export default function GoogleTimeline({event}) {

  // const {ref, inView, scrollDirection, entry, observe, unobserve } = useInView(
  //   {
  //     threshold: 0.25, // Default is 0
  //     onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
  //       ref.current.classList.add('is_inview')
  //     }

  //   }
  // );
  const [isInview, setIsInview] = useState(false);
    
  
  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }

  return (
  <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <article className={isInview ? 'is_inview event' : 'event'}>
        <div className="wrapper">
          <div className="image-wrap--outer fifty">
          {}
          {
            event.imageGatsbyGoogle != null 
            ? <Img 
              fluid={event.imageGatsbyGoogle.localFile.childImageSharp.fluid} 
              alt={event?.imageGatsbyGoogle?.altText} 
              /> 
            : "image not found"
          }
          </div>
          <div className="content-wrap fifty">
            <div className="indicator"><div className="circle"></div><div className="line"></div></div>
            <label className="date">{event.date}</label>
            <h2 className="title">{event.title}</h2>
            <div className="text">{parse(event.text)}</div>	
          </div>
        </div>
      </article>
      }
    </VisibilitySensor>
      
    
  )
}

import React, {useState} from 'react'
import RellaxWrapper from 'react-rellax-wrapper'
import VisibilitySensor from 'react-visibility-sensor';

export default function GoogleIntro({data}) {

  const [isInview, setIsInview] = useState(false);
    
  
  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }

  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
        <section className={isInview ? 'is_inview intro' : 'intro'}>
          <div className="wrap">
            <h2><span className="blue">G</span><span className="red">o</span><span className="yellow">o</span><span className="blue">g</span><span className="green">l</span><span className="red">e</span> Ads</h2>
            <span className="sub-title">a visual history</span>
          </div>
          <RellaxWrapper wrapper='#container-scroller'  className="img_wrap rellax" speed={-2.6}>
            <img className="intro_bg_1" src={data.heroImage1Google.localFile.publicURL} alt="Google Ads Timeline Image 1" />
          </RellaxWrapper>
          <RellaxWrapper wrapper='#container-scroller'  className="img_wrap rellax" speed={-.6}>
            <img className="intro_bg_2" src={data.heroImage2Google.localFile.publicURL}  alt="Google Ads Timeline Image 2" />
          </RellaxWrapper>
          <RellaxWrapper wrapper='#container-scroller'  className="img_wrap rellax" speed={-1.6}>
            <img className="intro_bg_3" src={data.heroImage3Google.localFile.publicURL}  alt="Google Ads Timeline Image 3" />
          </RellaxWrapper>
          <RellaxWrapper wrapper='#container-scroller'  className="img_wrap rellax" speed={-5.6}>
            <img className="intro_bg_4" src={data.heroImage4Google.localFile.publicURL}  alt="Google Ads Timeline Image 4" />
          </RellaxWrapper>
        </section>
      }
    </VisibilitySensor>
  )
}

import React, {useState} from 'react'
import RellaxWrapper from 'react-rellax-wrapper'
import VisibilitySensor from 'react-visibility-sensor';
import Img from 'gatsby-image';
import parse from 'html-react-parser'

export default function GoogleMiddle({data}) {
  const introText = parse(data.introText);
  const [isInview, setIsInview] = useState(false);
    
  
  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }


  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
      {({isVisible}) =>
       <section className={isInview ? 'is_inview text_wrap' : 'text_wrap'}>
        <div className="clip-mask">
          <RellaxWrapper wrapper='#container-scroller'   className="timeline_bg_1 img_wrap rellax" speed={0.6}>
            {data?.introImage1Google?.localFile?.childImageSharp?.fluid ? '' : 'image not found'}
            <Img 
            fluid={data?.introImage1Google?.localFile?.childImageSharp?.fluid} 
            alt={data?.introImage1Google?.altText} 
            />
          </RellaxWrapper>
        </div>
        <div className="wrapper">
          <div className="text">
            {introText}
          </div>
        </div>
        <div className="mask">
          <RellaxWrapper wrapper='#container-scroller'   className="timeline_bg_2 img_wrap rellax" speed={1.6}>
            {data?.introImage2Google?.localFile?.childImageSharp?.fluid ? '' : 'image not found'}
            <Img  
            fluid={data?.introImage2Google?.localFile?.childImageSharp?.fluid} 
            alt={data?.introImage2Google?.altText} 
            />
          </RellaxWrapper>
          <RellaxWrapper wrapper='#container-scroller'  className="timeline_bg_3 img_wrap rellax" speed={-5.6}>
            {data?.introImage3Google?.localFile?.childImageSharp?.fluid ? '' : 'image not found'}
            <Img 
            fluid={data?.introImage3Google?.localFile?.childImageSharp?.fluid} 
            alt={data?.introImage3Google?.altText} 
            />
          </RellaxWrapper>
        
          <RellaxWrapper wrapper='#container-scroller'  className="blob blob-1 rellax" speed={6.6}></RellaxWrapper>
          <RellaxWrapper wrapper='#container-scroller'  className="blob blob-2 rellax" speed={-1.6}></RellaxWrapper>
          <RellaxWrapper wrapper='#container-scroller'  className="blob blob-3 rellax" speed={-2.6}></RellaxWrapper>
        </div>
      </section>
      }
    </VisibilitySensor>
   
  )
}
